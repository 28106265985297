@font-face {
  font-family: GraphikRegular;
  src: url('./assets/fonts/GraphikRegular.otf');
}

@font-face {
  font-family: LibreBaskervilleRegular;
  src: url('./assets/fonts/LibreBaskerville-Regular.ttf');
}

@font-face {
  font-family: SFUIDisplaySemibold;
  src: url('./assets/fonts/FontsFree-Net-SF-UI-Display-Semibold.ttf');
}

@font-face {
  font-family: SFUIDisplayRegular;
  src: url('./assets/fonts/FontsFree-Net-SFProText-Regular.ttf');
}

.firebase-emulator-warning {
  display: none !important;
}
